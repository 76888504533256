<template>
	<div>
		<a-modal v-model:visible="visible" width="700px" @cancel="close" @ok="onSubmit">
			<template #title>
				<modalHeader :title="isEdit ? '编辑角色' : '新增角色'"></modalHeader>
			</template>
			
			<div>
				<a-form ref="formRef" :model="modelRef" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }">
					<a-form-item label="角色名称" name="name" :rules="[{ required: true, message: '请输入角色名称!' }, { min: 1, max: 20, message: '请输入字符为1~20之间的角色名称!'}]">
						<a-input v-model:value="modelRef.name" :maxlength="20" placeholder="请输入角色名称"></a-input>
					</a-form-item>
				</a-form>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	import { addRole, editRole } from '@/service/modules/system.js';
	export default {
		components: { Icon, modalHeader },
		data() {
			return {
				visible: false,
				isEdit: false,
				isOpen: false,
				modelRef: {}
			}
		},
		methods: {
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					this.loading = true;
					let ret;
					try {
						if (this.isEdit) {
							ret = await editRole(postData);
						} else {
							ret = await addRole(postData);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(this.isEdit ? '编辑成功' : '新增成功');
							this.$emit('refresh');
							this.close();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			open(e) {
				if (e && e.id) {
					this.modelRef = {
						id: e.id,
						isDisabled: e.isDisabled,
						name: e.name,
						parentRoleId: e.parentRoleId,
						description: e.description
					}
					this.isEdit = true;
				} else {
					this.isEdit = false;
					this.modelRef = {
						isDisabled: 1,
					}
				}
				this.visible = true;
			},
			close() {
				this.$refs.formRef.clearValidate();
				this.visible = false;
			}
		}
	}
</script>

<style>
</style>